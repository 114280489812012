<template>
  <b-table
      hover
      striped
      borderless
      head-variant="light"
      :items="domains"
      :fields="fields"
  >
    <template #cell(edit)="domain">
      <div class="row justify-content-end mr-0">
        <EditDeleteButtonGroup
            @edit="editDomain(domain.item)"
            @delete="deleteDomain(domain.item)"
        />
      </div>
    </template>
  </b-table>
</template>

<script>
import EditDeleteButtonGroup from "@/components/buttons/EditDeleteButtonGroup";
import {DateTime} from 'luxon';

export default {
  props: {
    domains: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {key: "name", label: "Nom"},
        {key: "type", label: "Type"},
        {
          key: "generationDate",
          label: "Date de génération",
          formatter: value => DateTime.fromISO(value).setLocale('fr').toFormat('dd LLLL yyyy')
        },
        {key: "edit", label: ""},
      ],
    };
  },
  methods: {
    editDomain(domain) {
      this.$router.push({
        name: "domain",
        params: {uid: domain.uid},
      });
    },
    async deleteDomain(domain) {
      try {
        await this.$store.dispatch("domains/deleteDomain", domain.uid);
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title: "Une erreur est survenue lors de la suppression du domaine",
          text: e.message,
        });
        return;
      }

      await this.$swal.fire({
        icon: "success",
        title: "Domain supprimé",
      });
    },
  },
  components: {
    EditDeleteButtonGroup,
  },
};
</script>