import Vue from 'vue'

import SkeletonTable from "@/components/SkeletonTable"

export default Vue.component('DomainListSkeleton', {
    functional: true,
    render(h) {
        return h(SkeletonTable, {
            props: {
                fields: [
                    {name: "Nom", skeletonProps: {width: "100%"}},
                    {name: "Type", skeletonProps: {width: "50%"}},
                    {name: "Date de génération", skeletonProps: {width: "70%"}},
                    {name: "", skeletonProps: {type: "button", width: "70%"}},
                ],
                rows: 10,
                tableProps: {borderless: true, striped: true, headVariant: 'light'},
            }
        })
    },
})